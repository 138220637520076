import * as React from "react";
const InstaSVGComponent = (props) => (
  <svg
    width={41}
    height={41}
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.5 20.5C40.5 30.28 33.4795 38.4211 24.2031 40.1577V26.9258H28.4798H28.9089L28.974 26.5016L29.8829 20.5758L29.9712 20H29.3887H24.2031V16.6562C24.2031 15.9175 24.3848 15.2498 24.7969 14.7731C25.1972 14.31 25.8793 13.9531 27.044 13.9531H29.6289H30.1289V13.4531V8.4082V7.98629L29.713 7.91533L29.6289 8.4082C29.713 7.91533 29.713 7.91532 29.7129 7.91531L29.7127 7.91528L29.7123 7.9152L29.7107 7.91493L29.705 7.91397L29.6836 7.9104L29.6026 7.89726C29.5321 7.88601 29.4297 7.87006 29.3 7.85097C29.0407 7.81281 28.6721 7.76205 28.2326 7.71131C27.3559 7.61008 26.1877 7.50781 25.04 7.50781C22.5977 7.50781 20.5233 8.2498 19.0588 9.71563C17.5938 11.182 16.7969 13.3149 16.7969 15.9836V20H12.0918H11.5918V20.5V26.4258V26.9258H12.0918H16.7969V40.1577C7.52052 38.4211 0.5 30.28 0.5 20.5C0.5 9.4543 9.4543 0.5 20.5 0.5C31.5457 0.5 40.5 9.4543 40.5 20.5Z"
      stroke={props?.color ? props?.color : "#222222"}
    />
  </svg>
);
export default InstaSVGComponent;
