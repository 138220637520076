import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import Logo from "../../assests/hoeny_hive_logo.png";
import CallICon from "../../assests/call.png";
import EmailICon from "../../assests/mail.png";
import Link from "next/link";
import { useRouter } from "next/router";
import SocialMediaCard from "../socialMediaCard";

const Header = () => {
  const router = useRouter();
  const [showMenu, setShowMenu] = useState(false);
  const menuObj = [
    { title: "About us", icon: "", path: "/about-us", type: "Link" },
    {
      title: "Services",
      icon: "",
      path: "",
      type: "Link",
      child: [
        {
          title: "Creative Staffing Agency",
          icon: "",
          path: "/services/creative-staffing-agency",
          type: "Link",
        },
        {
          title: "Job Seeker",
          icon: "",
          path: "/services/job-seeker",
          type: "Link",
        },
      ],
    },
    { title: "Contact us", icon: "", path: "/contact-us", type: "Link" },
    { title: "Blog", icon: "", path: "/blogs", type: "Link" },
    { title: "", icon: "", path: "/blogs", type: "Link" },
    {
      title: "",
      icon: CallICon,
      path: "tel:+91 9880501267",
      content: "+91 9880501267",
    },
    {
      title: "",
      icon: EmailICon,
      path: "mailto:vani@honeyhive.in",
      content: "vani@honeyhive.in",
    },
    {
      title: "I AM A COMPANY",
      icon: null,
      path: "/services/creative-staffing-agency",
      type: "Button",
    },
    {
      title: "  I AM A JOB SEEKER",
      icon: "",
      path: "/services/job-seeker",
      type: "Button",
    },
  ];

  return (
    <div
      className={`sm:max-w-[95%] mx-auto sm:bg-transparent bg-[#EEEDE2]  relative`}
    >
      <div className="md:px-12 flex items-center justify-end">
        <div className="lg:flex hidden  pt-4 items-center gap-[40px] md:gap-[20px] justify-evenly">
          {menuObj.map((items, index) => {
            return (
              <div className="text-3xl" key={index}>
                {items.type === "Link" ? (
                  <></>
                ) : items.icon ? (
                  <a href={items.path} className="flex  gap-4 items-center">
                    <Image src={items.icon} width={20} height={20} />
                    {items?.content}
                  </a>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex items-center sm:p-6 justify-between md:px-12 p-12">
        <Link className="aspect-auto" href={"/"}>
          <Image
            src={Logo}
            className="sm:hidden flex"
            alt="Honey Hive Logo"
            width={150}
            height={150}
          />
          <Image
            src={Logo}
            className="sm:flex hidden"
            alt="Honey Hive Logo"
            width={250}
            height={250}
          />
        </Link>
        <div className=" lg:flex hidden  items-center gap-[40px] md:gap-[20px] justify-evenly">
          {menuObj.map((items, index) => {
            return (
              <div className="group relative" key={index}>
                {items.type === "Link" ? (
                  <div>
                    <Link
                      className={` ${router.pathname === items.path ? "font-extrabold" : ""
                        }   text-[#585858] text-3xl  `}
                      href={!items?.child ? items.path : ""}
                    >
                      {items.title}
                    </Link>
                    <div className="group-hover:flex bg-[#EEEDE2] p-2 shadow-xl rounded-md hidden hover:flex  flex-col py-2   absolute min-w-max top-10 ">
                      {items?.child &&
                        items.child.map((ele) => {
                          return (
                            <Link
                              className={` ${router.pathname === ele.path
                                ? "font-extrabold"
                                : ""
                                }   text-[#585858] text-3xl group-hover:flex px-4 py-1 pt-4 `}
                              href={ele.path}
                            >
                              {ele.title}
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                ) : items.icon ? (
                  <></>
                ) : (
                  <Link
                    href={items.path}
                    className={` module-border-wrap  text-[#585858] text-3xl `}
                  >
                    <span className="text-[#EEEDE2] module  text-3xl">
                      {items.title}
                    </span>
                  </Link>
                )}
              </div>
            );
          })}
        </div>
        {/* menu icon */}
        <div className=" flex lg:hidden">
          <svg
            className="cursor-pointer"
            onClick={() => setShowMenu(!showMenu)}
            width="27"
            height="20"
            viewBox="0 0 27 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H25.9231M1 10H25.9231M1 19H25.9231"
              stroke="#222222"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <div
        style={{
          top: showMenu ? "0px" : "-102vh",
          opacity: showMenu ? "1" : "0",
          transition: "ease-in .4s",
        }}
        className="   xl:hidden  absolute  sm:bg-transparent bg-[#EEEDE2]    p-6 w-full min-h-[100vh]"
      >
        <div className=" w-full p-6 flex items-center justify-between">
          <Link
            onClick={() => setShowMenu(!showMenu)}
            className="aspect-auto"
            href={"/"}
          >
            <Image
              src={Logo}
              className="sm:hidden flex "
              alt="Honey Hive Logo"
              width={150}
              height={150}
            />
            <Image
              src={Logo}
              className="sm:flex hidden"
              alt="Honey Hive Logo"
              width={250}
              height={250}
            />
          </Link>
          <div className="flex  justify-end gap-16 ">
            {menuObj.slice(5, 8).map((item, index) => {
              return (
                item?.icon && (
                  <div onClick={() => setShowMenu(!showMenu)} key={item?.title}>
                    <Link
                      className={` ${router.pathname === item.path ? "font-bold" : ""
                        }   text-[#585858] text-3xl  `}
                      href={item?.path}
                    >
                      <Image src={item?.icon} width={25} height={25} />
                    </Link>
                  </div>
                )
              );
            })}
            <div>
              <svg
                onClick={() => setShowMenu(!showMenu)}
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.60303 1L20.6618 19M1.60303 19L20.6618 1"
                  stroke="#222222"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="flex sm:bg-transparent bg-[#EEEDE2]  flex-col items-end gap-12 py-12 px-6 justify-end">
          {menuObj.splice(0, 4).map((items, index) => {
            return (
              <div onClick={() => setShowMenu(!showMenu)} key={index}>
                {items.type === "Link" ? (
                  <div className="text-right">
                    {!items?.child && (
                      <Link
                        className={` ${router.pathname === items.path ? "font-extrabold" : ""
                          }   text-[#585858] text-3xl text-right  `}
                        href={!items?.child ? items.path : ""}
                      >
                        {items.title}
                      </Link>
                    )}
                    {items?.child && (
                      <div className=" flex-col flex py-2  gap-12 ">
                        {items.child.map((ele) => {
                          return (
                            <Link
                              className={` ${router.pathname === ele.path
                                ? "font-extrabold"
                                : ""
                                }   text-[#585858] text-3xl text-right group-hover:flex py-1 pt-4 `}
                              href={ele.path}
                            >
                              {ele.title}
                            </Link>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ) : (
                  items.type === "Button" && (
                    <Link
                      href={items.path}
                      className={`module-border-wrap font-semibold py-3  text-[#585858] text-3xl`}
                    >
                      <span className="text-[#EEEDE2] module font-semibold text-3xl">
                        {items.title}
                      </span>
                    </Link>
                  )
                )}
              </div>
            );
          })}
          <div
            onClick={() => setShowMenu(!showMenu)}
            className="flex gap-10 flex-col items-end"
          >
            <Link
              href={"/services/job-seeker"}
              className={`module-border-wrap py-4  text-[#585858] text-2xl`}
            >
              <span className="text-[#EEEDE2] module text-2xl">
                I AM A JOB SEEKER
              </span>
            </Link>

            <Link
              href={"/services/creative-staffing-agency"}
              className={`module-border-wrap py-4  text-[#585858] text-2xl`}
            >
              <span className="text-[#EEEDE2] module text-2xl">
                I AM A COMPANY
              </span>
            </Link>
          </div>
          <SocialMediaCard />
        </div>
      </div>
    </div>
  );
};

export default Header;
