import React from "react";
import SocialMediaCard from "../socialMediaCard";
import Link from "next/link";

const Footer = () => {
  const menuObj = [
    { title: "About us", icon: "", path: "/about-us", type: "Link" },
    { title: "Services", icon: "", path: "/services/creative-staffing-agency", type: "Link" },
    { title: "Contact us", icon: "", path: "/contact-us", type: "Link" },
    { title: "Blog", icon: "", path: "/blogs", type: "Link" },
  ];

  const HeadingStyles = {
    color: "#FFFFFF",
    fontWeight: "800",
    textTransform: "uppercase",
  };
  return (
    <div className="bg-[#585858]">
      <div className=" container mx-auto p-10 sm:p-24  gap-12 flex flex-wrap  sm:justify-between justify-evenly ">
        <div className="sm:hidden block  sm:min-w-0 min-w-full">
          {menuObj.map((item, i) => {
            return (
              <div className="text-white  text-4xl py-6  " key={i}>
                <Link href={item?.path}>{item?.title}</Link>
              </div>
            );
          })}
        </div>
        <div className="  sm:min-w-0 min-w-full">
          <div
            className="sm:text-6xl text-5xl font-semibold sm:font-bold cursor-default "
            style={HeadingStyles}
          >
            Contact
          </div>
          <div className="flex p-2 gap-10 py-6 ">
            <div className="pt-3">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6795 3.96759C15.2633 4.31217 15.6215 4.9396 15.6215 5.61748V14.3692C15.6215 15.4273 14.7638 16.285 13.7056 16.285H11.7898C10.7316 16.285 9.87387 15.4273 9.87387 14.3692V10.5373C9.87387 9.4792 9.0161 8.62143 7.95798 8.62143V8.62143C6.89987 8.62143 6.0421 9.4792 6.0421 10.5373V14.3692C6.0421 15.4273 5.18433 16.285 4.12621 16.285H2.21032C1.15221 16.285 0.294434 15.4273 0.294434 14.3692V5.61748C0.294434 4.9396 0.652655 4.31217 1.23643 3.96759L6.98409 0.574869C7.58492 0.220212 8.33105 0.220212 8.93188 0.57487L14.6795 3.96759Z"
                  stroke="#FFFFFF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="text-white text-3xl text-justify leading-loose cursor-default">
              Honeyhive Talent Solutions, 29, 3rd Cross Rd,{" "}
              <br className="hidden sm:block" /> near to Sony World signal,{" "}
              <br className="hidden sm:block" /> Venkata Reddy Layout, 6th
              Block, Koramangala, <br className="hidden sm:block" /> Bengaluru,
              Karnataka 560095
            </div>
          </div>
          <div className="flex gap-10 items-center justify-start py-6">
            <div>
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.425064 3.14226C1.21212 10.0999 7.13831 16.0261 14.0959 16.8131C15.0034 16.9158 15.7925 16.2571 15.9716 15.3616L16.3345 13.547C16.5039 12.6998 16.0854 11.844 15.3126 11.4576L14.1292 10.8659C13.3535 10.4781 12.4115 10.7004 11.8911 11.3942C11.5522 11.8461 11.0079 12.1216 10.487 11.9032C8.69659 11.1527 6.08545 8.54158 5.33494 6.75119C5.11658 6.23028 5.39208 5.68594 5.84393 5.34705C6.53773 4.8267 6.76012 3.88465 6.37228 3.10896L5.78057 1.92556C5.39419 1.1528 4.53841 0.734246 3.69122 0.903685L1.87658 1.26661C0.981043 1.44572 0.322408 2.23478 0.425064 3.14226Z"
                  stroke="#FFFFFF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="text-white text-[1.9rem]   ">
              <p>
                <span>
                  <a href="tel:+91 9880501267"> +91 9880501267</a> |{" "}
                  <a href="tel:+91 9901268388"> +91 9901268388 </a> |{" "}
                  <br className="sm:hidden block" />{" "}
                  <a href="tel:+91 9845128760">+91 9845128760 </a>
                </span>
              </p>
            </div>
          </div>
          <div className="flex gap-10 items-center justify-start py-6">
            <div>
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.37854 3.4627L7.93729 8.50187C8.58084 8.9309 9.41923 8.9309 10.0628 8.50187L17.6215 3.4627M2.29443 14.0001H15.7056C16.7638 14.0001 17.6215 13.1423 17.6215 12.0842V2.50475C17.6215 1.44664 16.7638 0.588867 15.7056 0.588867H2.29443C1.23631 0.588867 0.37854 1.44664 0.37854 2.50475V12.0842C0.37854 13.1423 1.23631 14.0001 2.29443 14.0001Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="text-white text-3xl  leading-relaxed ">
              <span>
                {" "}
                <a href="mailto:vani@honeyhive.in">vani@honeyhive.in</a> |{" "}
                <span>
                  {" "}
                  <a href="mailto:vani@honeyhive.in">
                    noufel@honeyhive.in
                  </a>{" "}
                </span>{" "}
              </span>
            </div>
          </div>
        </div>
        <div className=" sm:min-w-0 min-w-full">
          <div className="flex flex-col justify-between items-start h-full gap-10">
            <div
              className="sm:text-6xl  cursor-default text-5xl font-semibold sm:font-bold "
              style={HeadingStyles}
            >
              follow us
              <div className="pt-12 flex items-center justify-center">
                <SocialMediaCard color={"#fff"} />
              </div>
            </div>
            <div className="sm:flex hidden   md:h-[10px] items-center gap-10   min-w-full">
              {menuObj.map((item, i) => {
                return (
                  <div className="text-white  text-4xl  " key={i}>
                    <Link href={item?.path}>{item?.title}</Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
