import * as React from "react";
const XSVGComponent = (props) => (
  <svg
    width={45}
    height={45}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1452 0.965515C7.42437 0.965515 1.16135 7.22854 1.16135 14.9544C1.16135 22.6802 7.42437 28.9432 15.1502 28.9432C22.876 28.9432 29.139 22.6802 29.139 14.9544C29.139 7.22854 22.876 0.965515 15.1502 0.965515ZM0.44397 14.9544C0.44397 6.83234 7.02817 0.248138 15.1502 0.248138C23.2722 0.248138 29.8564 6.83234 29.8564 14.9544C29.8564 23.0764 23.2722 29.6606 15.1502 29.6606C7.02817 29.6606 0.44397 23.0764 0.44397 14.9544Z"
      fill={props?.color ? props?.color : "#222222"}
    />
    <path
      d="M19.6195 7.78061H22.0029L16.7959 13.8581L22.9215 22.1281H18.1252L14.3686 17.1124L10.0701 22.1281H7.68529L13.2547 15.6276L7.37836 7.78061H12.2964L15.6921 12.3652L19.6195 7.78061ZM18.783 20.6713H20.1037L11.5788 9.16092H10.1616L18.783 20.6713Z"
      fill={props?.color ? props?.color : "#222222"}
    />
  </svg>
);
export default XSVGComponent;
