import React, { useState, useEffect } from "react";

const ScrollToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div
        style={{
          opacity: showButton ? 1 : 0,
          cursor: showButton ? "pointer" : "",
          transition: "ease-in-out .4s",
          rotate:'180deg'
        }}
        className={`fixed right-10 bottom-[50px] z-10 sm:max-w-[50px] sm:left-30 sm:bottom-30`}
        onClick={showButton ? scrollToTop : null}
      >
        <svg
          width="48"
          height="61.8"
          viewBox="0 0 78 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="39" fill="#ED9D21" fill-opacity="0.15" />
          <path
            d="M37.9393 59.0607C38.5251 59.6464 39.4749 59.6464 40.0607 59.0607L49.6066 49.5147C50.1924 48.9289 50.1924 47.9792 49.6066 47.3934C49.0208 46.8076 48.0711 46.8076 47.4853 47.3934L39 55.8787L30.5147 47.3934C29.9289 46.8076 28.9792 46.8076 28.3934 47.3934C27.8076 47.9792 27.8076 48.9289 28.3934 49.5147L37.9393 59.0607ZM37.5 20L37.5 58L40.5 58L40.5 20L37.5 20Z"
            fill="black"
          />
        </svg>
      </div>
    </>
  );
};

export default ScrollToTop;
