import { React, useEffect } from "react";
import "@/styles/globals.css";
import "../styles/heroSection.scss";
import Layout from "../components/layouts";

export default function App({ Component, pageProps }) {
  return (
    <>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}
