import React, { useState, useEffect } from "react";
import Footer from "../footer";
import { useRouter } from "next/router";
import ScrollToTop from "../Molecules/ScrollToTop";
import Header from "../header";

const Layout = ({ children }) => {
  const router = useRouter();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.pageYOffset > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen   ">
      {" "}
      {!router.pathname.includes("/admin") && (
        <div
          style={{
            zIndex: 12,
            transition: "ease-in-out .2s",
          }}
          className={`fixed ${
            scrolled ? "bg-[#EEEDE2]" : "sm:bg-transparent"
          } bg-[#EEEDE2]  top-0 w-full`}
        >
          <Header />
        </div>
      )}
      <div className={` min-h-screen   sm:mt-0  mt-[10rem] `}> {children} </div>
      <>
        <Footer />
      </>
      <div className="">
      <ScrollToTop />
      </div>
    </div>
  );
};

export default Layout;
