import * as React from "react";
const LinkedInSVGComponent = (props) => (
  <svg
    width={45}
    height={45}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.05374 14.9544C1.05374 7.22857 7.31676 0.965546 15.0426 0.965546C22.7684 0.965546 29.0314 7.22857 29.0314 14.9544C29.0314 22.6802 22.7684 28.9432 15.0426 28.9432C7.31676 28.9432 1.05374 22.6802 1.05374 14.9544ZM15.0426 0.248169C6.92057 0.248169 0.336365 6.83237 0.336365 14.9544C0.336365 23.0764 6.92057 29.6606 15.0426 29.6606C23.1646 29.6606 29.7488 23.0764 29.7488 14.9544C29.7488 6.83237 23.1646 0.248169 15.0426 0.248169ZM7.583 12.4902H10.8345V22.2595H7.583V12.4902ZM11.0486 9.46808C11.0274 8.51021 10.3425 7.78063 9.23016 7.78063C8.11786 7.78063 7.39067 8.51021 7.39067 9.46808C7.39067 10.4061 8.09636 11.1567 9.18796 11.1567H9.20873C10.3425 11.1567 11.0486 10.4061 11.0486 9.46808ZM18.8166 12.2607C20.9563 12.2607 22.5603 13.6573 22.5603 16.658L22.5602 22.2594H19.3088V17.0329C19.3088 15.7201 18.8383 14.8242 17.6613 14.8242C16.763 14.8242 16.2279 15.4281 15.993 16.0114C15.907 16.2205 15.8859 16.5117 15.8859 16.8037V22.2597H12.634C12.634 22.2597 12.6769 13.4071 12.634 12.4904H15.8859V13.8741C16.3174 13.2089 17.0903 12.2607 18.8166 12.2607Z"
      fill={props?.color ? props?.color : "#222222"}
    />
  </svg>
);
export default LinkedInSVGComponent;
