import * as React from "react";
const YoutubeSVGComponent = (props) => (
  <svg
    width={45}
    height={45}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0964 0.965517C7.37053 0.965517 1.10751 7.22854 1.10751 14.9544C1.10751 22.6802 7.37053 28.9432 15.0964 28.9432C22.8222 28.9432 29.0852 22.6802 29.0852 14.9544C29.0852 7.22854 22.8222 0.965517 15.0964 0.965517ZM0.390137 14.9544C0.390137 6.83234 6.97434 0.248138 15.0964 0.248138C23.2184 0.248138 29.8026 6.83234 29.8026 14.9544C29.8026 23.0764 23.2184 29.6606 15.0964 29.6606C6.97434 29.6606 0.390137 23.0764 0.390137 14.9544ZM22.4287 11.4111C22.2527 10.735 21.7341 10.2026 21.0756 10.0219C19.8822 9.69349 15.0965 9.69349 15.0965 9.69349C15.0965 9.69349 10.3107 9.69349 9.11728 10.0219C8.45877 10.2026 7.94017 10.735 7.76417 11.4111C7.44444 12.6365 7.44444 15.1934 7.44444 15.1934C7.44444 15.1934 7.44444 17.7501 7.76417 18.9756C7.94017 19.6517 8.45877 20.1842 9.11728 20.365C10.3107 20.6933 15.0965 20.6933 15.0965 20.6933C15.0965 20.6933 19.8822 20.6933 21.0756 20.365C21.7341 20.1842 22.2527 19.6517 22.4287 18.9756C22.7485 17.7501 22.7485 15.1934 22.7485 15.1934C22.7485 15.1934 22.7485 12.6365 22.4287 11.4111ZM13.6616 13.0415V17.824L17.4876 15.4328L13.6616 13.0415Z"
      fill={props?.color ? props?.color : "#222222"}
    />
  </svg>
);
export default YoutubeSVGComponent;
